/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Tabs, Button, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { maskPhoneNumber } from 'components/common/PhoneNumberMask';
import { store } from 'redux/store';
import { axiosInstance } from 'services/axios';

const { TabPane } = Tabs;

const LeadHistoryTabs = (props) => {
  const { userReducer } = store.getState();
  const userPermissions = userReducer?.userDetails?.permissions?.map(permission => permission.codename);
  const isCustomerExist = !props?.customer || Object.keys(props?.customer).length === 0;

  return (
    <Tabs defaultActiveKey="1" onChange={props?.handleTabChange}>
      <TabPane tab="Details" key="1">
        <CustomerDetails
          customer={props?.customer}
          handleCustomerEdit={props.handleCustomerEdit}
          isLoading={props?.isLoading}
        />
      </TabPane>
      {!isCustomerExist && userPermissions?.includes('view_schedule') && (
        <TabPane tab="Schedule" key="2">
          <ScheduleDetails
            schedule={props?.schedule}
            loadingSchedule={props?.loadingSchedule}
            handleCustomerEdit={props.handleCustomerEdit}
            customer={props?.customer}
          />
        </TabPane>
      )}
      {props?.leadType === 'rejoining' && (
        <TabPane tab="Driver Details" key="3">
          <DriverDetails
            driver={props?.driver}
            isLoadingDriver={props?.isLoadingDriver}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

const CustomerDetails = ({ customer, handleCustomerEdit, isLoading }) => {
  const isCustomerEmpty = !customer || Object.keys(customer).length === 0;

  if (isLoading) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {!isCustomerEmpty ? <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='customerDetails' >
        <div className='row'>
          <div className='col-md-10'>
            <div>
              <b>Name: </b>
							<span>{`${customer?.name}`}</span>
            </div>
            <div>
              <b>Phone: </b>
              <span>{maskPhoneNumber(customer?.phone)}</span>
            </div>
            {customer?.location &&
              <div>
                <b>Location: </b>
                <span>{customer?.location}</span>
              </div>
            }
            {customer?.lead_source &&
              <div>
                <b>Source: </b>
                <span>{customer?.lead_source}</span>
              </div>
            }
            <div>
              <b>Connected Calls: </b>
              <span>{customer?.no_of_connected_calls}</span>
            </div>
            <div>
              <b>Not Connected Calls: </b>
              <span>{customer?.no_of_unconnected_calls}</span>
            </div>
            {customer?.referred_by && 
              <div>
                <b>Referred By: </b>
                <span>{customer?.referred_by}</span>
              </div>
            }
            {customer?.remarks &&
              <div>
                <b>Remarks: </b>
                <span>{customer?.remarks}</span>
              </div>
            }

            {/* Is interested field listing... */}

            {customer?.is_interested && (
              <>
                <div>
                  <b>Is Interested?: </b>
                  <span>{customer?.is_interested ? 'Yes' : 'No'}</span>
                </div>
                {customer?.has_uber_id !== null && (
                  <div>
                    <b>Uber Id?: </b>
                    <span>{customer?.has_uber_id ? 'Yes' : 'No'}</span>
                  </div>
                )}
                {customer?.vehicle_type && (
                  <div>
                    <b>Vehicle Type: </b>
                    <span>{customer?.vehicle_type}</span>
                  </div>
                )}
                {customer?.businessmodal_name && (
                  <div>
                    <b>Business Modal?: </b>
                    <span>{customer?.businessmodal_name}</span>
                  </div>
                )}
                {customer?.has_document !== null && (
                  <div>
                    <b>Has Document?: </b>
                    <span>{customer?.has_document ? 'Yes' : 'No'}</span>
                  </div>
                )}

                {customer?.is_willing_to_pay !== null && (
                  <div>
                    <b>Is Willing To Pay?: </b>
                    <span>{customer?.is_willing_to_pay ? 'Yes' : 'No'}</span>
                  </div>
                )}

                {customer?.is_willing_to_pay && (
                  <>
                    {customer?.type_of_amount && (
                      <div>
                        <b>Type Of Amount: </b>
                        <span>{customer?.type_of_amount}</span>
                      </div>
                    )}
                    {customer?.amount && (
                      <div>
                        <b>Amount: </b>
                        <span>{customer?.amount}</span>
                      </div>
                    )}
                  </>
                )}
                {customer?.is_willing_to_pay === false && customer?.reason && (
                  <div>
                    <b>Not Willing Reason: </b>
                    <span>{customer?.reason}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='col-md-2'>
            <Button
              title='Edit'
              type="default"
              shape="round"
              icon={<EditOutlined />}
              onClick={() => handleCustomerEdit(customer, 'customer')}
              style={{ padding: '5.6px 14px' }}
            />
          </div>
        </div>
      </fieldset >
        :
        <fieldset className={'tabErrorNotification'}>
          <div className='row'>
            Customer details not found. The record will be created now and shown in subsequent calls.
          </div>
        </fieldset>}
    </>
  )
};

const ScheduleDetails = ({ schedule, loadingSchedule, handleCustomerEdit, customer }) => {
  if (loadingSchedule) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin />
      </div>
    );
  }

  return (
    <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='scheduleDetails'>
      <div className='row'>
        <div className='col-md-10'>
          <div>
            <b>Date: </b>
            <span>{schedule?.date}</span>
          </div>
          <div>
            <b>Time Slots: </b>
            <span>{schedule?.timeslot}</span>
          </div>
          <div>
            <b>Location / Hub: </b>
            <span>{schedule?.location_hub?.location}</span>
          </div>
        </div>
        <div className='col-md-2'>
          <Button
            title='Edit'
            type="default"
            shape="round"
            icon={<EditOutlined />}
            onClick={() => handleCustomerEdit(schedule, 'schedule')}
            style={{ padding: '5.6px 14px' }}
          />
        </div>
      </div>
    </fieldset>
  );
};

const DriverDetails = ({ driver, isLoadingDriver }) => {
  const isDriverEmpty = !driver || Object.keys(driver).length === 0;

  if (isLoadingDriver) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {!isDriverEmpty ? <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='driverDetails'>
        <div className='row'>
          <div className='col-md-8'>
            <div>
              <b>Employee Id: </b>
              <span>{driver?.hawkeye_employee_id}</span>
            </div>
          </div>
        </div>
      </fieldset>
        :
        <fieldset className={'tabErrorNotification'}>
          <div className='row'>
            Driver details not found. The record will be created now and shown in subsequent calls.
          </div>
        </fieldset>}
    </>
  )
};

export default LeadHistoryTabs;
