/* eslint-disable */
import React from 'react';
import { Drawer, Spin, Form, DatePicker, Select, Input, Button, Space, Radio } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

const EditScheduleDrawer = ({ onCloseSchedule, isScheduleDrawer, loadingSchedule, ScheduleFormData, formErrors, onChangeScheduleFields, locationHubs, bussinessModals, onSaveSchedule, form, customer, isWillingToPay }) => {

  return (
    <Drawer
      title="Edit Schedule"
      width={480}
      onClose={onCloseSchedule}
      open={isScheduleDrawer}
    >
      <Spin spinning={loadingSchedule}>
        <Form layout="vertical" form={form}>
          <Form.Item>
            <div>
              <strong>Name: </strong>
							{customer?.name} &nbsp;&nbsp;
							<strong>Phone: </strong>{customer?.phone}
            </div>
          </Form.Item>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Form.Item
              name="date"
              label="Date"
              validateStatus={formErrors?.date ? 'error' : null}
              help={formErrors?.date}
              style={{ flexBasis: '50%' }}
            >
              <DatePicker
                name="date"
                onChange={(date, dateString) => onChangeScheduleFields(dateString, 'date')}
                style={{ width: '100%' }}
                disabledDate={(current) => current && current < moment().startOf('day')}
              />
            </Form.Item>

            <Form.Item
              name="timeslot"
              label="Time Slots"
              validateStatus={formErrors?.timeslot ? 'error' : null}
              help={formErrors?.timeslot}
              style={{ flexBasis: '50%' }}
            >
              <Select
                placeholder={'Select a timeslot'}
                name="timeslot"
                onChange={(value) => onChangeScheduleFields(value, 'timeslot')}
                style={{ width: '100%' }}
              >
                <Select.Option value='10AM-12PM'>10AM-12PM</Select.Option>
                <Select.Option value='12PM-2PM'>12PM-2PM</Select.Option>
                <Select.Option value='2PM-4PM'>2PM-4PM</Select.Option>
                <Select.Option value='4PM-6PM'>4PM-6PM</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            name="location_hub"
            label="Location / Hub"
            validateStatus={formErrors?.location_hub ? 'error' : null}
            help={formErrors?.location_hub}
          >
            <Select
              placeholder={'Select a location'}
              name="location_hub"
              onChange={(value) => onChangeScheduleFields(value, 'location_hub')}
            >
              {locationHubs?.map((location, index) => (
                <Select.Option key={index} value={location.id}>
                  {location.location}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="vehicle_type"
            label="What Product is he interested in taking?"
            validateStatus={formErrors?.vehicle_type ? 'error' : null}
            help={formErrors?.vehicle_type}
          >
            <Select
              placeholder={'Select a vehicle type'}
              name="vehicle_type"
              onChange={(value) => onChangeScheduleFields(value, 'vehicle_type')}
            >
              <Select.Option value="EV">EV</Select.Option>
              <Select.Option value="CNG">CNG</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="businessmodal"
            label="What plan is he interested in taking?"
            validateStatus={formErrors?.businessmodal ? 'error' : null}
            help={formErrors?.businessmodal}
          >
            <Select
              placeholder={'Select a business modal'}
              name="businessmodal"
              onChange={(value) => onChangeScheduleFields(value, 'businessmodal')}
            >
              {bussinessModals?.map((modal, index) => (
                <Select.Option key={index} value={modal?.id}>
                  {modal?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="remarks"
            label="Remarks"
            validateStatus={formErrors?.remarks ? 'error' : null}
            help={formErrors?.remarks}
          >
            <Input
              name="remarks"
              onChange={(e) => onChangeScheduleFields(e.target.value, 'remarks')}
            />
          </Form.Item>

          <hr />

          <Form.Item 
            name="is_willing_to_pay"
            label="Is he willing to pay the amount?"
          >
            <Radio.Group
              onChange={(e) => onChangeScheduleFields(e.target.value, 'is_willing_to_pay')}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          {/* If YES: Show payment type dropdown and amount input */}
          {isWillingToPay === true && (
            <>
              <Form.Item 
                name="type_of_amount"
                label="What type of amount is he willing to pay now?"
              >
                <Select
                  placeholder="Select Payment Type"
                  onChange={(value) => onChangeScheduleFields(value, 'type_of_amount')}
                >
                  <Select.Option value="sd">SD</Select.Option>
                  <Select.Option value="token">Token</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item 
                name="amount"
                label="Amount (Only Numeric Value)"
              >
                <Input
                  type="number"
                  placeholder="Enter amount"
                  onChange={(e) => onChangeScheduleFields(e.target.value, 'amount')}
                />
              </Form.Item>
            </>
          )}

          {/* If NO: Show reason input */}
          {isWillingToPay === false && (
            <Form.Item 
              name="reason"
              label="Note the reason why he is not willing"
            >
              <Input.TextArea
                placeholder="Enter reason"
                onChange={(e) => onChangeScheduleFields(e.target.value, 'reason')}
              />
            </Form.Item>
          )}

          <Space>
            <Button
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              onClick={onSaveSchedule}
              style={{ display: "flex", alignItems: "center" }}
              loading={loadingSchedule}
            >
              Update
            </Button>

            <Button
              type="danger"
              shape="round"
              icon={<CloseOutlined />}
              onClick={onCloseSchedule}
              style={{ display: "flex", alignItems: "center" }}
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditScheduleDrawer;
