import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Drawer, Form, Input, Modal, notification, Radio, Select, Space, Spin } from 'antd';
import { CustomerServiceOutlined, CloseOutlined, LoginOutlined, MinusOutlined, EditOutlined, PlusOutlined, HistoryOutlined, CheckOutlined } from '@ant-design/icons';
import './Ameyo.css';
import { axiosInstance } from 'services/axios';
import moment from 'moment';
import { useSelector } from 'react-redux';

import LeadHistoryTabs from 'components/common/LeadHistoryTabs';
import EditScheduleDrawer from './schedule/EditScheduleDrawer';
import PermissionButton from './PermissionButton';
import { formatDateTime } from './HandleDateTimeFormat';

const Ameyo = (props) => {
  const { userReducer } = useSelector((state) => state);
  const { activeScheduleReportTab } = useSelector((state) => state.userReducer);

  const userId = userReducer?.userDetails?.telecaller_credentials?.telecaller_userid;
  const userPass = userReducer?.userDetails?.telecaller_credentials?.telecaller_passwd;
  const allCities = userReducer?.userDetails?.cities;

  const [isLoading, setIsLoading] = useState(false);
  const [isAmeyoLoading, setIsAmeyoLoading] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isCallHistoryDrawerOpen, setIsCallHistoryDrawerOpen] = useState(false);
  const [leadHistories, setLeadHistories] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [cities, setCities] = useState(userReducer?.userDetails?.cities);
  const [isScheduleDrawer, setIsScheduleDrawer] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [ameyoCallHistoryParam, setAmeyoCallHistoryParam] = useState(null);
  const [bussinessModals, setBussinessModals] = useState([]);
  const [locationHubs, setLocationHubs] = useState([]);
  const [initialScheduleFormData, setInitialScheduleFormData] = useState({});
  const [oldScheduleFormData, setOldScheduleFormData] = useState(null);
  const [isGlobalCalling, setIsGlobalCalling] = useState(false);
  const [form] = Form.useForm();
  const [assignees, setAssignees] = useState([])
  const [callTypeStatus, setCallTypeStatus] = useState(null)
  const [isCalling, setIsCalling] = useState(false)
  const [leadType, setLeadType] = useState(null);
  const [activeLeadId, setActiveLeadId] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [customer, setCustomer] = useState({});
  const [schedule, setSchedule] = useState(null);
  const [driver, setDriver] = useState(null);
  const [isLoadingDriver, setIsLoadingDriver] = useState(false);
  const [campaignName, setCampaignName] = useState(null);
  const [moduleName, setModuleName] = useState('')
  const [isInterested, setIsInterested] = useState(false);
  const [isWillingToPay, setIsWillingToPay] = useState(null);

  const canEditAssigneeField = useMemo(() => {
    return userReducer?.userDetails?.roles?.filter((role) => !["Tele Caller", "Scheduler"].includes(role.name))?.length > 0
  }, [userReducer?.userDetails]);

  useEffect(() => {
    const { hash } = window.location;

    if (hash.includes('lead/rejoining')) {
      setLeadType('rejoining');
    } else if (hash.includes('lead')) {
      setLeadType('lead');
    }
  }, [userReducer?.userDetails, window.location.hash])

  const fetchCampaign = async (ameyo_campaign_id) => {
    try {
      const params = { ameyo_campaign_id };

      const response = await axiosInstance.get('lead/campaign/details/', { params });
      setCampaignName(response.data?.name);
    } catch (error) {
      console.log("Couldn't fetch campaigns !")
    }
  }

  const fetchCallHistory = async (phone) => {
    try {
      const params = { phone };
      setAmeyoCallHistoryParam(params);

      const historyResponse = await axiosInstance.get('lead/callhistory/view/', { params });
      const customerResponse = await axiosInstance.get('lead/view/', { params });

      setIsLoading(false);
      setLeadHistories(historyResponse.data);
      setCustomer(customerResponse.data)
    } catch (error) {
      setIsLoading(false);
      setCustomer({})
    }
  }

  const handleAmeyo = () => {
    props.setIsAmeyoOpen(true);
    setIsMinimized(false);

    document.querySelector('body').style.overflowY = 'auto'
    if (document.getElementById('crmPage')) document.getElementById('crmPage').innerHTML = '';

    // Show Ameyo Login button if not logged in
    if (window.initialize) {
      document.getElementById('ameyoLoginButton').style.display = 'block';
    }
  }

  const customShowCrm = (phone, additionalParams, requestId) => {
    // Resetting the call history state here
    setLeadHistories([]);
    setCustomer({})
    setCampaignName(null)
    setIsCallHistoryDrawerOpen(false)

    window.newAddParam = JSON.parse(additionalParams);

    // Submitting details here...
    fetch(`${process.env.REACT_APP_CRM_API_URL}/lead/call/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(window.newAddParam)
    })
      .then((response) => response.json())
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));

    console.log("Executed");

    // Storing phone number for reload history page on call dispose here...
    window.currentPhoneNumber = window.newAddParam?.displayPhone
    window.campaignId = window.newAddParam?.campaignId

    // Getting call history data here...
    if (window.newAddParam?.displayPhone && !window.isCallHistoryDrawerOpen) {
      setActiveTab('1');
      setIsLoading(true);

      // Fetching campaign details here...
      fetchCampaign(window.newAddParam?.campaignId);

      // Fetching call history details...
      fetchCallHistory(window.newAddParam?.displayPhone, window.newAddParam?.campaignId);

      setIsCallHistoryDrawerOpen(true)
      // Opening Ameyo modal on inComing call here..
      handleAmeyo();
    }

    // Checking call type here for display current call status...
    setIsCalling(true)

    if (window.newAddParam?.callType.includes('outbound')) {
      setCallTypeStatus('Outgoing')
    } else {
      setCallTypeStatus('Incoming')
    }

    const crmPage = document.getElementById('crmPage');
    const html = `Sending request to get CRM data for phone: ${phone}
        Additional Parameters: ${additionalParams}
        <br> Receiving Response..
        <br> Populating CRM data on the basis of response..
        <br> Done`;
    crmPage.innerHTML = `${html}<br>${crmPage.innerHTML}`;
    window.ameyo.integration.api.setRecordInfoForShowCrm(requestId, requestId, phone, window.setRecordForShowCrmCallback);
  }

  const initializeCRMUI = () => {
    const crmPage = document.getElementById('crmPage');
    const html = "UI initialization ";
    crmPage.innerHTML = `${html}<br>${crmPage.innerHTML}`;
  }

  const handleLoginStatus = (status) => {
    if (status === "loggedIn") {
      document.getElementById('ameyoLoginButton').style.display = 'none';
      window.initialize = false;
      window.isCallHistoryDrawerOpen = false;
    } else {
      document.getElementById('ameyoLoginButton').style.display = 'block';
    }

    const crmPage = document.getElementById('crmPage');
    const html = `handleLoginStatus: ${status}`;
    crmPage.innerHTML = `${html}<br>${crmPage.innerHTML}`;
  }

  const handleForceLogin = (reason) => {
    if (reason === "success") {
      window.initialize = false;
    }

    const crmPage = document.getElementById('crmPage');
    const html = `handleForceLogin: ${reason}`;
    crmPage.innerHTML = `${html}<br>${crmPage.innerHTML}`;
  }

  function handleLogout(reason) {
    // Displaying login button after logout...
    if (reason === "success") document.getElementById('ameyoLoginButton').style.display = 'block';

    const crmPage = document.getElementById('crmPage');
    const html = `Logged out : ${reason}`;
    crmPage.innerHTML = `${html}<br>${crmPage.innerHTML}`;
  }

  const customCallDispose = (userCustomerCRTInfo) => {
    // Fetching call history...
    setTimeout(() => {
      fetchCallHistory(window.currentPhoneNumber, window.campaignId);
      setIsCalling(false)
    }, 500);

    const crmPage = document.getElementById('crmPage');
    const html = `Call disposed <br> 
    User CRT info: ${userCustomerCRTInfo.userCrtObjectId} <br> 
    Customer CRT Info: ${userCustomerCRTInfo.customerCrtObjectId}`;

    crmPage.innerHTML = `${html}<br>${crmPage.innerHTML}`;
  }

  const initialise = () => {
    setIsAmeyoLoading(true);
    const crmOrigin = `${window.location.origin}`
    const iframeDiv = document.getElementById("ameyoIframeDiv");
    const iframeUrl = `${window?.ameyoBaseUrl}ameyowebaccess/toolbar-crm.htm?origin=${crmOrigin}`;
    const iframeHtml = `<iframe height="450px" width="398px" id="ameyoIframe" src="${iframeUrl}" allow="microphone *"></iframe>`;

    if (iframeDiv) {
      iframeDiv.innerHTML = iframeHtml;
      const iframe = document.getElementById("ameyoIframe");

      if (iframe) {
        iframe.onload = () => {
          if (document.querySelector('.ant-modal-wrap')) document.querySelector('.ant-modal-wrap').style.position = 'inherit'

          setTimeout(() => {
            setIsAmeyoLoading(false);
          }, 3000);
        };
      }
    }
  }

  const fetchAssignees = async () => {
    const params = {
      city: parseInt(localStorage.getItem("userDefaultCity"))
    }

    const query = new URLSearchParams(params);

    await axiosInstance.get(`/users/assignees/?${query.toString()}`)
      .then((response) => {
        setAssignees(response.data)
      })
      .catch(() => {
        notification.error({
          message: "Couldn't load assignees!"
        });
      })
  }

  useEffect(() => {
    if (props.isAmeyoOpen) {
      initialise();

      if (typeof window.ameyo !== 'undefined') {
        window.customIntegration.showCrm = customShowCrm;
        window.customIntegration.intializeUI = initializeCRMUI;
        window.customIntegration.loginStatusHandler = handleLoginStatus;
        window.customIntegration.forceLoginHandler = handleForceLogin;
        window.customIntegration.logoutHandler = handleLogout;
        window.customIntegration.disposeCall = customCallDispose;

        window.ameyo.integration.registerCustomFunction("showCrm", window.customIntegration);
        window.ameyo.integration.registerCustomFunction("intializeUI", window.customIntegration);
        window.ameyo.integration.registerCustomFunction("loginStatusHandler", window.customIntegration);
        window.ameyo.integration.registerCustomFunction("forceLoginHandler", window.customIntegration);
        window.ameyo.integration.registerCustomFunction("logoutHandler", window.customIntegration);
        window.ameyo.integration.registerCustomFunction("disposeCall", window.customIntegration);
      }
    }

  }, [props.isAmeyoOpen]);

  const toggleMinimize = (e) => {
    setIsMinimized(!isMinimized);

    document.querySelector('body').style.overflowY = 'auto'
    const modalWrap = document.querySelector('.ant-modal-wrap');

    if (modalWrap) {
      if (!isMinimized) {
        modalWrap.style.zIndex = 0;
        modalWrap.style.position = 'inherit';
        document.getElementById('ameyoLoginButton').style.display = 'none';
      } else {
        modalWrap.style.zIndex = 1000
      }
    }
  };

  const handleAmeyoCustomLogin = () => {
    setIsAmeyoLoading(true);

    if (userId && userPass) {
      window.ameyo.integration.doLogin(
        userId?.toString(),
        userPass?.toString()
      );

      document.getElementById('ameyoLoginButton').style.display = 'none';
      setTimeout(() => {
        setIsAmeyoLoading(false);
      }, 2000);
    } else {
      console.error('Invalid telecaller credentials!')
    }
  }

  const hasChanges = () => {
    // Compare the current form data with the initial form data
    return JSON.stringify(initialScheduleFormData) !== JSON.stringify(oldScheduleFormData);
  };

  const getFormFieldsData = (data) => ({
    date: data?.date ? moment(data?.date.toString()) : null,
    timeslot: data?.timeslot,
    location_hub: data?.location_hub?.id ? data?.location_hub.id : data?.location_hub,
    vehicle_type: data?.vehicle_type,
    businessmodal: data?.businessmodal?.id ? data?.businessmodal.id : data?.businessmodal,
    remarks: data?.remarks,
    is_willing_to_pay: data?.is_willing_to_pay,
    is_interested: data?.is_interested,
    type_of_amount: data?.type_of_amount?.toLowerCase(),
    reason: data?.reason,
    amount: data?.amount,
    lead: data?.lead_id,
    has_uber_id: data?.has_uber_id,
    has_document: data?.has_document,
    id: data?.id,
  });

  const setFormFields = (data) => {
    form.setFieldsValue(getFormFieldsData(data));
    setIsWillingToPay(data?.is_willing_to_pay);
  };

  const openCallHistoryModal = async (leadId, phone) => {
    setIsCallHistoryDrawerOpen(true)
    setIsCalling(false)

    if (leadId && !phone) {
      setIsLoading(true)
      setActiveLeadId(leadId)
      const historyResponse = await axiosInstance.get(`/lead/callhistory/view/?lead=${leadId}`);
      const customerResponse = await axiosInstance.get(`/lead/view/?lead=${leadId}`);

      setLeadHistories(historyResponse.data);
      setCustomer(customerResponse.data)
      setActiveTab('1');
      setIsLoading(false)
    }

    // Calling
    if (leadId && phone) {
      setIsLoading(true)
      setTimeout(() => {
        console.log('Calling to from Ameyo:', Number(phone));
        window.ameyo.integration.doDial(phone, null, null, null, null, null);

        // Reset the button attributes after the call is initiated
        const callHistoryButton = document.getElementById('callHistoryButton');
        if (callHistoryButton) {
          callHistoryButton.setAttribute('data-leadid', '');
          callHistoryButton.setAttribute('data-phone', '');
        }
      }, 3000);
    }
  };

  const onCloseCallHistoryModal = () => {
    setIsCallHistoryDrawerOpen(false)
    setActiveLeadId(null)
    setCustomer(null)
    setSchedule(null)
    setDriver(null)
    setAmeyoCallHistoryParam(null)
    setLeadHistories([]);
    setModuleName('')
  };

  const fetchBusinessAndLocation = async () => {
    try {
      const query = {
        permission: "optional",
        city: parseInt(localStorage.getItem("userDefaultCity") || cities[0])
      }

      const [businessResponse, locationResponse] = await Promise.all([
        axiosInstance.get(`/lead/businessmodals/?permission=optional`),
        axiosInstance.get(`/lead/locationhubs/?${new URLSearchParams(query).toString()}`),
      ]);

      setBussinessModals(businessResponse?.data?.results);
      setLocationHubs(locationResponse?.data?.results);
    } catch (error) {
      notification.error({
        message: "Couldn't load details!",
      });
    }
  };

  const handleCustomerEdit = async (customerDetails, type = '', eventIsGlobalCalling = false, isModule = '') => {
    setIsGlobalCalling(eventIsGlobalCalling);
    // // Fetching business and location data here
    fetchBusinessAndLocation();

    if (type === 'customer') {
      const selectedCity = Number(localStorage.getItem('userDefaultCity'));
      const cityId = JSON.parse(localStorage.getItem('cities'))
      const filterCity = cityId?.filter((city) => city.id === selectedCity)

      setCities(filterCity);
      setFormErrors({});
      fetchAssignees();

      const details = {
        id: customerDetails?.id,
        name: customerDetails?.name,
        phone: customerDetails?.phone,
        city: customerDetails?.city?.id,
        location: customerDetails?.location,
        assignee: customerDetails?.assignee?.id,
        lead_source: customerDetails?.lead_source,
        is_interested: customerDetails?.is_interested,
        is_willing_to_pay: customerDetails?.is_willing_to_pay,
        has_uber_id: customerDetails?.has_uber_id,
        has_document: customerDetails?.has_document,
        vehicle_type: customerDetails?.vehicle_type,
        businessmodal: customerDetails?.businessmodal,
        remarks: customerDetails?.remarks,
        referred_by: customerDetails?.referred_by,
        type_of_amount: customerDetails?.type_of_amount?.toLowerCase(),
        reason: customerDetails?.reason,
        amount: customerDetails?.amount,
      };
      
      setIsInterested(customerDetails?.is_interested);
      setIsWillingToPay(customerDetails?.is_willing_to_pay);
      setIsCustomerEdit(true);
      setIsCallHistoryDrawerOpen(false)
      setFormValues(details)
    }
    if (type === 'schedule') {
      // Filtering out not-existing locationHub ids...
      const isLocationAvailable = locationHubs.some(
        (loc) => loc.id === customerDetails?.location_hub?.id
      );
      customerDetails = { ...customerDetails, location_hub: isLocationAvailable ? {...customerDetails.location_hub} : null }
      
      // Replacing schedule data with customer for specific keys...
      const scheduleDetails = customerDetails || {};

      if (isModule === 'schedule-report') {
        setCustomer(customerDetails)
        setModuleName(isModule)
      } else {
        // Replacing schedule data with customer for specific keys...
        scheduleDetails.vehicle_type = customer?.vehicle_type;
        scheduleDetails.businessmodal = customer?.businessmodal ? { ...scheduleDetails.businessmodal, id: customer?.businessmodal } : scheduleDetails.businessmodal;
        scheduleDetails.remarks = customer?.remarks;
        scheduleDetails.is_willing_to_pay = customer?.is_willing_to_pay;
        scheduleDetails.is_interested = customer?.is_interested;
        scheduleDetails.type_of_amount = customer?.type_of_amount?.toLowerCase();
        scheduleDetails.reason = customer?.reason;
        scheduleDetails.amount = customer?.amount;
        scheduleDetails.has_uber_id = customer?.has_uber_id;
      }

      setIsCallHistoryDrawerOpen(false);
      setFormErrors({});

      setTimeout(() => {
        setFormFields(scheduleDetails);
        setIsScheduleDrawer(true);

        // Holding data for the validate that is changed or not while saving...
        setOldScheduleFormData(scheduleDetails);
        setInitialScheduleFormData(scheduleDetails)
      }, 0);
    }
  };

  // Handling global calling of schedule and edit customer from schedule-report and lead page...
  useEffect(() => {
    const handleCustomEditEvent = (event) => {
      const { rowData, type, isGlobalCalling: eventIsGlobalCalling } = event.detail;
      handleCustomerEdit(rowData, type, eventIsGlobalCalling, event.detail?.module);
    };

    window.addEventListener('handleCustomerAndScheduleEvent', handleCustomEditEvent);

    return () => {
      window.removeEventListener('handleCustomerAndScheduleEvent', handleCustomEditEvent);
    };
  }, []);

  // Handleing customer update...
  const onChange = (e) => {
    const { name, value, checked = null } = e.target;

    if (name === 'is_interested') setIsInterested(checked);
    if (name === 'is_willing_to_pay') setIsWillingToPay(value);

    // Validation on phone input
    if (name === 'phone' && value) {
      if (!/^[1-9]\d{0,9}$/.test(value)) return;
    }

    setFormValues((prev) => ({ ...prev, [name]: name === 'is_interested' ? checked : value }));
  };

  const onChangeSelect = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: (field === 'city') ? value?.toString() : value }));
  };

  const handleViewHistory = () => {
    setIsCustomerEdit(false);
    setIsCallHistoryDrawerOpen(true)
  };

  const onCloseModal = () => {
    setIsCustomerEdit(false)
    if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true)
  };

  const validateForm = (formData) => {
    const errors = {};
    const requiredFields = ["name"];
  
    // Phone number validation
    if (formData.phone) {
      const phonePattern = /^[1-9]\d{9}$/;
      if (!phonePattern.test(formData.phone)) {
        errors.phone = "The phone number must be 10 digits!";
      }
    } else {
      errors.phone = "This field is required!";
    }
  
    // Validate all required fields in a single loop
    requiredFields.forEach((field) => {
      if (!formData[field] && formData[field] !== false) {
        errors[field] = "This field is required!";
      }
    });
    // Set errors in state
    setFormErrors(errors);
    
    return Object.keys(errors).length === 0;
  };

  const handleUpdate = async () => {
    if (validateForm(formValues)) {
      setIsLoading(true);

      const data = {
        ...formValues
      }

      await axiosInstance.put(`/lead/${data?.id}/`, data).then((response) => {
        notification.success({
          message: `Customer updated successfully!`
        });

        // Resettings states here...
        setCustomer(response?.data);
        setIsCustomerEdit(false);
        setIsLoading(false);
        if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true)

        // This is for updating schedule report row from here...
        window.dispatchEvent(new CustomEvent('updateCustomerRowAfterUpdate'));
      }).catch((error) => {
        setIsLoading(false);
        const leadErrors = error?.response?.data;
        
        if (leadErrors?.type === "field") {
          setFormErrors(leadErrors)
        } else if (leadErrors && Object.keys(leadErrors)?.length) {
          Object.entries(leadErrors).forEach(([key, messages]) => {
            if (Array.isArray(messages)) {
              messages.forEach((message) => {
                notification.error({
                  message: `${key.charAt(0).toUpperCase() + key.slice(1)} ${message.replace("This ", "").trim()}`
                });
              });
            }
          });                    
        } else {
          notification.error({ message: `Some Error occured, Bad request!` });
        }
      })
    }
  }

  const onCloseSchedule = () => {
    setFormErrors({});
    setIsScheduleDrawer(false);
    if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true);
  };

  const validateScheduleForm = () => {
    const requiredFields = [
      { field: 'date', message: 'Please select a date' },
      { field: 'timeslot', message: 'Please select a time slot' },
      { field: 'location_hub', message: 'Please select a location' },
    ];

    const errors = requiredFields.reduce((acc, { field, message }) => {
      if (!initialScheduleFormData?.[field]) {
        acc[field] = message;
      }
      return acc;
    }, {});

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onChangeScheduleFields = (value, name) => {
    setInitialScheduleFormData((prev) => ({ ...prev, [name]: name === 'is_willing_to_pay' ? value : value?.toString() }));
    if (name === 'is_willing_to_pay') setIsWillingToPay(value);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const fetchCustomerDetails = async () => {
    setIsLoading(true)
    try {
      const params = ameyoCallHistoryParam || { lead: activeLeadId };
      const response = await axiosInstance.get('lead/view/', { params });
      setCustomer(response.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Failed to fetch customer details:', error);
    }
  };

  const fetchScheduleDetails = async () => {
    setLoadingSchedule(true)
    try {
      const params = ameyoCallHistoryParam || { lead: activeLeadId };
      const response = await axiosInstance.get('lead/schedule/view/', { params });
      setSchedule(response.data);

      setInitialScheduleFormData(response.data)
      // Holding for validate
      setOldScheduleFormData(response.data)
      setLoadingSchedule(false)
    } catch (error) {
      setLoadingSchedule(false)
      console.error('Failed to fetch schedule details:', error);
    }
  };

  const fetchDriverDetails = async () => {
    setIsLoadingDriver(true)
    try {
      const params = ameyoCallHistoryParam || { lead: activeLeadId };
      const response = await axiosInstance.get('lead/driver/', { params });
      setDriver(response.data);
      setIsLoadingDriver(false)
    } catch (error) {
      setIsLoadingDriver(false)
      console.error('Failed to fetch driver details:', error);
    }
  };

  const onSaveSchedule = async () => {
    if (validateScheduleForm()) {
      // Checking formdata is changed or not here..
      if (!hasChanges()) {
        notification.info({
          message: 'No changes were made to the schedule.',
        });

        return;
      }

      setLoadingSchedule(true);

      try {
        // Prepare the data for submission
        const updatedScheduleData = getFormFieldsData(initialScheduleFormData);

        updatedScheduleData.date = moment(updatedScheduleData.date).format('YYYY-MM-DD');

        if (!schedule && moduleName === '') {
          updatedScheduleData.lead = customer?.id;
        }

        const response = await axiosInstance.post('/lead/schedule/', updatedScheduleData);

        setLoadingSchedule(false);
        setIsScheduleDrawer(false);
        if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true);

        notification.success({ message: `Schedule updated successfully!` });

        fetchCustomerDetails();

        // Updating lead table data after schedule update...
        window.dispatchEvent(new CustomEvent('updateCustomerRowAfterUpdate'));

        // This is for updating schedule report row from here...
        window.dispatchEvent(new CustomEvent('updateScheduleRowAfterUpdate', {
          detail: { activeTab: activeScheduleReportTab }
        }));

        // Reset the form after successful submission
        setInitialScheduleFormData(response?.data);
        setSchedule(response?.data);

      } catch (error) {
        setLoadingSchedule(false);
        // Handling errors here...
        let message = "Couldn't save the schedule. Please try again.";
        const errorData = error.response?.data;
        const field = errorData?.date || errorData?.timeslot || errorData?.location_hub || errorData?.remarks;

        if (field) message = field;
        notification.error({ message });
      }
    }
  };
  
  useEffect(() => {
    if (activeTab === '1' && isCallHistoryDrawerOpen) {
      fetchCustomerDetails();
    } else if (activeTab === '2') {
      fetchScheduleDetails();
    } else if (activeTab === '3') {
      fetchDriverDetails();
    }
  }, [activeTab]);

  const getStatusIcon = (status) => {
    if (status === 'Connected') {
      return <CheckOutlined style={{ color: 'green', fontSize: '15px' }} title={status} />;
    }
    if (status === 'Not Connected') {
      return <CloseOutlined style={{ color: 'red', fontSize: '15px' }} title={status} />;
    }

    return status;
  };
  
  return (
    <>
      {/* {userPermissions?.includes('can_call_lead') && ( */}
      <div style={{ zIndex: '99999999', display: 'flex' }}>
        {userId && userPass
          ? <PermissionButton
            style={{ top: '1px', right: '40px', border: 'none', fontWeight: '500' }}
            type="default"
            className="ant-btn ameyoButton"
            icon={<CustomerServiceOutlined style={{ display: 'inline-flex' }} />}
            onClick={handleAmeyo}
            id='ameyoButton'
            permissions={['can_call_lead']}
          >Phone</PermissionButton>
          : ''}

        <Button id="ameyoLoginButton" style={{ top: '1px', right: '40px', border: 'none', fontWeight: '500', display: 'none' }} className="ant-btn ameyoButton" onClick={handleAmeyoCustomLogin}>
          <LoginOutlined style={{ display: 'inline-flex' }} /> Ameyo Login
        </Button>

        {/* Call history button */}
        <Button
          id="callHistoryButton"
          style={{ top: '1px', right: '40px', border: 'none', fontWeight: '500', display: 'none' }}
          className="ant-btn ameyoButton"
          onClick={(e) => openCallHistoryModal(e.target.getAttribute('data-leadid'), e.target.getAttribute('data-phone'))}
          data-leadid=""
          data-phone=""
        >
          <CustomerServiceOutlined style={{ display: 'inline-flex' }} /> Call History
        </Button>
      </div>
      {/* )} */}

      <Modal
        title={
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          >
            <span>Ameyo</span>
            <Button
              icon={isMinimized ? <PlusOutlined /> : <MinusOutlined />}
              onClick={(e) => toggleMinimize(e)}
              size="small"
            />
          </div>
        }
        open={props.isAmeyoOpen}
        onOk={() => props.setIsAmeyoOpen(false)}
        onCancel={() => props.setIsAmeyoOpen(false)}
        style={{
          position: "fixed",
          bottom: 42,
          left: 0,
          height: isMinimized ? '0px' : 'auto',
          overflow: 'hidden',
          border: '1px solid rgb(240 240 240)',
          borderRadius: '8px 8px 0 0',
          zIndex: 999999
        }}
        width={isMinimized ? '0px' : '448px'}
        footer={null}
        closable={false}
        maskClosable={false}
        mask={false}
        className={isMinimized ? 'ameyo-modal minimized' : 'ameyo-modal'}
      >
        {(isAmeyoLoading || isLoading) && (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spin />
          </div>
        )}
        <div id="ameyoIframeDiv" style={{ display: isMinimized ? 'none' : 'block' }} />
        <div id="crmPage" align="middle" style={{ overflow: 'auto', height: '0px' }} />
      </Modal>

      {/* Lead history drawer */}
      <div id="leadHistory">
        <Drawer
          title="Lead History"
          open={isCallHistoryDrawerOpen}
          onClose={onCloseCallHistoryModal}
          size="medium"
          mask={false}
          id="leadHistoryDrawer"
          width={425}
        >
          {(isLoading || loadingSchedule || isLoadingDriver) && (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Spin />
            </div>
          )}
          {!isLoading && isCalling
            ? <>

              <fieldset style={{ padding: "10px", borderColor: "#c31c1c", color: '#c31c1c' }}>
                <strong>Call in Progress: </strong>
                <div className='row' style={{ fontSize: "14px", display: 'flex' }}>
                  <div className='row'>
                    <div className='col-md-3'>
                      <b>Phone: </b>
                    </div>
                    <div className='col-md-9'>
                      <span>{window.currentPhoneNumber} <b>{callTypeStatus ? `(${callTypeStatus})` : null}</b></span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'>
                      <b>Campaign: </b>
                    </div>
                    <div className='col-md-9' style={{ wordWrap: 'break-word' }}>
                      <span>{campaignName}</span>
                    </div>
                  </div>
                </div>
              </fieldset>
              <hr />
            </>
            : ''}
          {(!isLoading || !loadingSchedule || !isLoadingDriver) && customer ? (
            <LeadHistoryTabs
              customer={customer}
              schedule={schedule}
              driver={driver}
              handleCustomerEdit={handleCustomerEdit}
              onCloseSchedule={onCloseSchedule}
              onSaveSchedule={onSaveSchedule}
              onChangeScheduleFields={onChangeScheduleFields}
              isScheduleDrawer={isScheduleDrawer}
              bussinessModals={bussinessModals}
              locationHubs={locationHubs}
              loadingSchedule={loadingSchedule}
              formErrors={formErrors}
              ScheduleFormData={initialScheduleFormData}
              isCallHistoryDrawerOpen={isCallHistoryDrawerOpen}
              leadType={leadType}
              handleTabChange={handleTabChange}
              isLoadingDriver={isLoadingDriver}
              isLoading={isLoading}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              {/* <p>No record found.</p> */}
            </div>
          )}

          {!isLoading && leadHistories?.length ? (
            leadHistories?.map((history, index) => {
              if (history.status?.label === 'Connected' && history.duration > 0) {
                return (
                  <React.Fragment key={history.id || index}>
                    <fieldset style={{ padding: "10px", background: "aliceblue" }}>
                      <legend>{formatDateTime(history.call_start_time)}</legend>
                      <div>
                        <b>Call Type: </b>
                        <span>{history.call_type}</span>
                      </div>
                      {(history?.duration !== 0) && <div>
                        <b>Call Duration: </b>
                        <span>{history?.duration}s</span>
                      </div>}
                      <div>
                        <b>Status: </b>
                        <span>{getStatusIcon(history.status?.label)}</span>
                      </div>
                      <div>
                        <b>Disposition: </b>
                        <span>{history?.disposition?.name}{history?.subdisposition?.name ? ` -> ${history?.subdisposition?.name}` : ''}</span>
                      </div>
                      {(history?.remarks) && <div>
                        <b>Remarks: </b>
                        <span>{history?.remarks}</span>
                      </div>}
                      {formatDateTime(history.call_back_time)
                        && <div>
                          <b>Call Back Time: </b>
                          <span>{formatDateTime(history.call_back_time)}</span>
                        </div>}
                      {history?.telecaller_name
                        && <div>
                          <b>Telecaller: </b>
                          <span>{history?.telecaller_name}</span>
                        </div>}
                    </fieldset>
                  </React.Fragment>
                )
              }

              return null;
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              {/* <p>No record found.</p> */}
            </div>
          )}
        </Drawer>
      </div>

      {/* Edit Schedule Details */}
      <EditScheduleDrawer
        onCloseSchedule={onCloseSchedule}
        isScheduleDrawer={isScheduleDrawer}
        loadingSchedule={loadingSchedule}
        ScheduleFormData={initialScheduleFormData}
        formErrors={formErrors}
        onChangeScheduleFields={onChangeScheduleFields}
        locationHubs={locationHubs}
        bussinessModals={bussinessModals}
        onSaveSchedule={onSaveSchedule}
        form={form}
        customer={customer}
        isWillingToPay={isWillingToPay}
        isInterested={isInterested}
      />
      {/* Edit Customer details */}
      <Drawer
        title={'Edit Customers'}
        open={isCustomerEdit}
        onClose={onCloseModal}
        footer={null}
        mask={false}
      >
        {/* Handle view history */}
        {!isGlobalCalling
          && <Button title='View History' type="default" shape="round" icon={<HistoryOutlined />} onClick={() => handleViewHistory(leadHistories)} style={{ left: '80%', color: '#5ba3e8', borderColor: '#5ba3e8' }}></Button>}

        <Form layout="vertical">
          <Form.Item
            label="Name *"
            validateStatus={formErrors?.name ? "error" : null}
            help={formErrors?.name}
          >
            <Input
              placeholder="Please enter the name."
              name="name"
              value={formValues.name}
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item
            label="Phone *"
            validateStatus={formErrors?.phone ? "error" : null}
            help={formErrors?.phone}
          >
            <Input
              placeholder="Please enter the phone."
              name="phone"
              value={formValues.phone}
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item
            label="Cities *"
            validateStatus={formErrors?.city ? "error" : null}
            help={formErrors?.city}
          >
            <Select
              showSearch
              placeholder="Please select a city"
              optionFilterProp="children"
              onChange={(value) => onChangeSelect('city', value)}
              options={allCities?.map((city) => ({ label: city.name, value: city?.id?.toString() }))}
              value={formValues.city?.toString()}
            />
          </Form.Item>

          <Form.Item
            label="Assignee"
            validateStatus={formErrors?.assignee ? "error" : null}
            help={formErrors?.assignee}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Select a assignee"
              onChange={(value) => onChangeSelect('assignee', value)}
              value={formValues.assignee?.toString()}
              filterOption={(input, option) => {
                if (option.value) {
                  return option.children.map((o) => o.toLowerCase()).join("").includes(input?.toLowerCase())
                }

                return null;
              }}
              disabled={!canEditAssigneeField}
            >
              <Select.Option style={{ color: "#bfbfbf", fontWeight: "normal" }} value="" key={new Date().toISOString()}>
                Select a assignee
              </Select.Option>
              {assignees.map((assignee, index) => (
                <Select.Option key={index} value={assignee?.id?.toString()}>
                  {assignee.first_name} {assignee.last_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Location"
            validateStatus={formErrors?.location ? "error" : null}
            help={formErrors?.location}
          >
            <Input
              placeholder="Please enter the location."
              name="location"
              value={formValues.location}
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item
            label="Source"
            validateStatus={formErrors?.lead_source ? "error" : null}
            help={formErrors?.lead_source}
          >
            <Input
              placeholder="Please enter the source."
              name="lead_source"
              value={formValues?.lead_source}
              onChange={onChange}
              readOnly
            />
          </Form.Item>

          <Form.Item
            label="Remarks"
            validateStatus={formErrors?.remarks ? "error" : null}
            help={formErrors?.remarks}
          >
            <Input
              placeholder="Please enter the remarks."
              name="remarks"
              value={formValues?.remarks}
              onChange={onChange}
              maxLength={200}
            />
          </Form.Item>

          <Form.Item
            label="Referred By"
            validateStatus={formErrors?.referred_by ? "error" : null}
            help={formErrors?.referred_by}
          >
            <Input
              placeholder="Enter referral source (if any)."
              name="referred_by"
              value={formValues?.referred_by}
              onChange={onChange}
            />
          </Form.Item>

          <hr />
            
          <Form.Item
            label={null}
            validateStatus={null}
          >
            <Checkbox
              onChange={onChange}
              name="is_interested"
              checked={formValues?.is_interested}
            >
              Is Interested?
            </Checkbox>
          </Form.Item>

          {isInterested && (
            <>
              <Form.Item label="Has Uber ID? *">
                <Radio.Group
                  name="has_uber_id"
                  onChange={onChange}
                  value={formValues?.has_uber_id}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="What Product is he interested in taking? *"
                validateStatus={formErrors?.vehicle_type ? "error" : null}
                help={formErrors?.vehicle_type}
              >
                <Radio.Group
                  name="vehicle_type"
                  onChange={onChange}
                  value={formValues?.vehicle_type}
                >
                  <Radio value="CNG">CNG</Radio>
                  <Radio value="EV">EV</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="What plan is he interested in taking? *"
                validateStatus={formErrors?.businessmodal ? 'error' : null}
                help={formErrors?.businessmodal}
              >
                <Select
                  placeholder="Select a business modal"
                  onChange={(value) => onChangeSelect('businessmodal', value)}
                  options={bussinessModals?.map((modal) => ({ label: modal?.name, value: modal?.id?.toString() }))}
                  value={formValues.businessmodal?.toString()}
                />
              </Form.Item>

              <Form.Item label="Does he have document handy? *">
                <Radio.Group
                  name="has_document"
                  onChange={onChange}
                  value={formValues?.has_document}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Is he willing to pay the amount?">
                <Radio.Group
                  name="is_willing_to_pay"
                  onChange={onChange}
                  value={formValues?.is_willing_to_pay}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {/* If YES: Show payment type dropdown and amount input */}
              {isWillingToPay && (
                <>
                  <Form.Item 
                    label="What type of amount is he willing to pay now?"
                    validateStatus={formErrors?.type_of_amount ? "error" : null}
                    help={formErrors?.type_of_amount}
                  >
                    <Select
                      placeholder="Select Payment Type"
                      name="type_of_amount"
                      onChange={(value) => onChangeSelect("type_of_amount", value)}
                      value={formValues?.type_of_amount}
                    >
                      <Select.Option value="sd">SD</Select.Option>
                      <Select.Option value="token">Token</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item 
                    label="Amount (Only Numeric Value)"
                    validateStatus={formErrors?.amount ? "error" : null}
                    help={formErrors?.amount}
                  >
                    <Input
                      type="number"
                      placeholder="Enter amount"
                      name="amount"
                      onChange={onChange}
                      value={formValues?.amount}
                    />
                  </Form.Item>
                </>
              )}

              {/* If NO: Show reason input */}
              {isWillingToPay === false && (
                <Form.Item 
                  label="Reason *"
                  validateStatus={formErrors?.reason ? "error" : null}
                  help={formErrors?.reason}
                >
                  <Input.TextArea
                    placeholder="Enter reason"
                    name="reason"
                    onChange={onChange}
                    value={formValues?.reason}
                  />
                </Form.Item>
              )}
            </>
          )}

          <Form.Item>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={formValues?.id ? <EditOutlined /> : <PlusOutlined />}
                onClick={handleUpdate}
                style={{ display: "flex", alignItems: "center" }}
                loading={isLoading}
              >
                {"Update"}
              </Button>

              <Button
                type="danger"
                shape="round"
                icon={<CloseOutlined />}
                onClick={onCloseModal}
                style={{ display: "flex", alignItems: "center" }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Ameyo;
