/* eslint-disable */

const correctDateTimeFormat = (datetimeStr) => {
    const datetimeRegex = /T(\d{1,2}):(\d{1,2}):(\d{1,2})/;
    const matches = datetimeStr.match(datetimeRegex);

    if (matches) {
        const hours = matches[1]?.length === 1 ? `0${matches[1]}` : matches[1];
        const minutes = matches[2]?.length === 1 ? `0${matches[2]}` : matches[2];
        let seconds = matches[3]?.length === 1 ? `0${matches[3]}` : matches[3];
        const formattedTime = `T${hours}:${minutes}:${seconds}`;
      
        return datetimeStr.replace(datetimeRegex, formattedTime);
    } else {
        return null;
    }
};

export const formatDateTime = (datetimeStr) => {
    if (!datetimeStr || datetimeStr?.includes('1970')) return null;

    const correctedDatetimeStr = correctDateTimeFormat(datetimeStr);    
    const date = new Date(correctedDatetimeStr);

    if (isNaN(date.getTime())) return null;

    const formattedDate = date.toLocaleDateString('en-CA');
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', options);

    return `${formattedDate} ${formattedTime}`;
};